const itemHeights = [];
let returnHeight;

$(function () {
  $(".js-accordion-text").each(function () {
    const thisHeight = $(this).height();
    const btnHeight = $(this).parent().find(".js-accordion-btn").outerHeight();
    itemHeights.push(thisHeight + btnHeight);
    if (thisHeight > 200) {
      $(this).addClass("is-hide");
      returnHeight = $(this).height();
    } else {
      $(this).parent().find(".js-accordion-btn").addClass("is-show");
      $(this).parent().find(".js-accordion-btn").hide();
      $(this).find("::before").hide();
    }
  });
});

$(".js-accordion-btn").click(function () {
  if (!$(this).hasClass("is-show")) {
    const index = $(this).index(".js-accordion-btn");
    const addHeight = itemHeights[index];
    $(this).addClass("is-show").next().animate({ height: addHeight }, 300);
  } else {
    $(this)
      .removeClass("is-show")
      .next()
      .animate({ height: returnHeight }, 300);
  }
});

$(".js-summary-accordion-btn").click(function () {
  if (!$(this).hasClass("is-show")) {
    const prev = $(this).prev();
    $(this).addClass("is-show");
    $(this).parent().addClass("is-show");
    prev.animate({ height: "100%" }, 300);
  } else {
    const prev = $(this).prev();
    $(this).removeClass("is-show");
    $(this).parent().removeClass("is-show");
    prev.animate({ height: "200px" }, 300);
  }
});
